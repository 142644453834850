.main {
    display: flex;
    align-items: center;
}

.active {

}

.icon {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #FF625C;
}

.active .icon {
    background: #83E163;
}

.title {
    margin-left: 6px;
    margin-right: 6px;
}
