
.ant-form-item-label {
    text-align: left;
    font-weight: 500;
    white-space: normal;
}

.ant-form-item-label > label {
    display: inline-block;
    color: #627582;
    line-height: 1.5;
}

.ant-form label {
    font-size: 13px;
}


.ant-upload-disabled {
    opacity: .7;
}

.ant-upload-disabled .ant-btn:hover, .ant-upload-disabled .ant-btn:focus {
    color: #000;
    background-color: #fff;
    border-color: #d9d9d9;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: #fff;
}

.ant-upload-picture-card-wrapper {

}