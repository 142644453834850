.container {
    display: flex;
    min-height: 300px;
}

.chart {
    flex: 1;
    width: 50%;
    margin-right: 16px;
}
.chartWrap {
    height: 100%;
}

.table {
    flex: 1;
    min-width: 450px;
}