.task {
    padding: 2px 4px;
    font-size: 11px;
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: rgba(0, 0, 0, .8);
    background: #8e8e8e;
    border: 1px solid #656565;
    border-radius: 2px;
}

.isDragging {
    opacity: .5;
}


.status0 {
    background: #F2A600;
    border: 1px solid #bc7f00;
}

.status1 {
    background: #65c16f;
    border: 1px solid #3c9445;
}

.status2 {
    background: #65c16f;
    border: 1px solid #3c9445;
}

.status3 {
    background: #b5b5b5;
    border: 1px solid #8e8e8e;
}

.status4 {
    background: #b5b5b5;
    border: 1px solid #8e8e8e;
}

.status10 {
    background: #3db9d3;
    border: 1px solid #2898b0;
}
