.main {

}

.orderBtn {
    height: 48px;
    padding: 0 15px;
    font-size: 14px;
    border-radius: 4px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .15);
}

.orderBtnIcon {
    background: rgba(255, 255, 255, .4);
    border-radius: 3px;
    padding: 4px 4px 2px;
}