.container {
  position: relative;
  border-left: 1px solid #E7EBF6;
}

.main {
  max-width: 200px;
  height: 80px;
  overflow: hidden;

  padding: 0 0 2px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.mainPhoto {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  width: 28px;
  height: 28px;
  font-size: 16px;
  border-radius: 50%;
  color: #425C84;
}

.mainText {
  flex: 1;
  overflow: hidden;

  font-size: 14px;
  line-height: 1.2;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #425C84;
}

.mainIcon {
  margin-left: 8px;
  color: #425C84;
  font-size: 12px;
}

.btnTitle {
  margin-bottom: 1px;
  font-size: 16px;

}

.menu {
  position: absolute;
  z-index: 9999;
  top: 80px;
  right: 20px;
  max-width: 250px;
  width: 100%;
}

.menu::after {
  content: '';
  position: absolute;
  z-index: 1;
  top: -8px;
  right: 11px;
  width: 16px;
  height: 16px;
  transform: rotate(45deg);
  border: 1px solid #efefef;
  background: #fff;
}

.menu::before {
  position: absolute;
  z-index: 0;
  top: -4px;
  bottom: 0;
  left: -4px;
  right: 0;
  height: 100%;
  width: 100%;
  margin: 4px;
  border-radius: 8px;
  content: '';
  background: #fff;
  border-top: 1px solid #efefef;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
}

.menuInner {
  position: relative;
  z-index: 2;
  padding: 0;
  border-radius: 8px;
  background: #fff;
  overflow: hidden;
}

.menuItem {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 15px;
  margin: 0;
  text-align: center;
  cursor: pointer;
  color: #000;
}

.menuItem:hover {
  background: rgba(49, 150, 232, 0.1);
}

.menuIcon {
  margin-right: 5px;
  font-size: 14px;
  color: #425C84;
}

.menuText {
  font-size: 14px;
  line-height: 1;
  color: #425C84;
}
