.page-block {
    position: relative;
}

.page-block.fill {
    padding: 16px;
    background: #fff;
    border: 1px solid #E7EBF6;
    border-radius: 4px;
    overflow: hidden;
}

.page-block-title {
    font-size: 18px;
    color: rgb(62, 98, 127);
    margin-bottom: 16px;
}

.page-block-header {
    display: flex;
    align-items: center;
    width: 100%;
}

.page-block-header-start {
    flex: 1;
}

.page-block-content {
    position: relative;
    flex: 1;
    width: 100%;
}
