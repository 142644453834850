
.ant-modal-close-x {
    width: 70px;
    height: 70px;
    line-height: 70px;
    font-size: 20px;
    color: #a2b9c8;
}

.ant-modal-header {
    padding: 24px 64px 24px 32px;
    border-bottom-color: #EFF0F4;
}

.ant-modal-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
}

.ant-modal-content {
    background: #fff;
}

.ant-modal-body {
    background: #FBFCFD;
}

.ant-modal-footer {
    padding: 16px 32px;
    text-align: center;
}


.modal-footer-inner {
    display: flex;
}

.modal-footer-start, .modal-footer-end {
    min-width: 100px;
    display: flex;
    align-items: center;
}

.modal-footer-middle {
    flex: 1;
}

.ant-popover-content {
    background: #fff;
}