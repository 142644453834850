.month-calendar {
    /*height: calc(100vh - 195px);*/
    background: #fff;
    border: 1px solid #E7EBF6;
    border-radius: 4px;
}

.headings {
    display: flex;
    align-items: flex-end;
    height: 35px;
    /*background: #0fc;*/
}

.headings .col {
    flex: 1;
    padding: 5px 10px;
    font-size: 12px;
    text-transform: uppercase;
    text-align: right;
    border-bottom: 1px solid #E7EBF6;
}

.month-body .row {
    display: flex;
}

.month-body .row .col {
    position: relative;
    flex: 1;
    height: calc((100vh - 195px - 35px) / 5);
    /*height: 20vh;*/
    border-right: 1px solid #E7EBF6;
    border-bottom: 1px solid #E7EBF6;
}
.month-body .row .col:last-child {
    border-right: 0;
}
.month-body .row:last-child .col {
    border-bottom: 0;
}

.cell-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    font-size: 12px;
}

.cell-container .dt {
    position: absolute;
    top: 10px;
    right: 10px;
}

.off-month {
    color: #D8DADD;
}

.weekend {
    background: #f7f7f7;
}

.today {

}