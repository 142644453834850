.photo {
    padding: 2px;
    border: 1px solid #ccc;
}

.photo-inner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: 50% 50%;
    background-color: #efefef;
}

.photo-empty {
    text-align: center;
    color: #666;
    font-size: 12px;
}

.photo-empty .anticon {
    font-size: 24px;
}

.photo-empty--avatar, .photo-empty--avatar .anticon {
    font-size: 1em;
}
