.photoDetail {
  width: 100%;
}

.photos {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -16px;
}

.photo {
  width: 50%;
  padding: 16px;
}
