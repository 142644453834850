.diagram {

}

.header {
    display: flex;
    padding-left: 94px;
    padding-right: 10px;
}

.slot {
    flex: 1;
    font-size: 13px;
    padding: 5px;
    border-right: 1px solid #eee;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    background: #fefefe;
    text-align: center;
}

.slot:first-child {
    border-left: 1px solid #eee;
}

.slotInner{
    flex: 1;
    font-size: 13px;
}

.body {
    height: 500px;
    overflow-y: scroll;
}

.body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

.body::-webkit-scrollbar {
    width: 10px;
    background-color: #F5F5F5;
}

.body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #858585;
}

