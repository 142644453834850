.card {
    border-radius: 4px;
    padding: 16px;
    background: #fff;
    border: 1px solid #E6E9EE;
}

.cardTitle {
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 1;
    color: #1a3643;
}

.cardMain {
    display: flex;
}

.cardChart {
    width: 50%;
}
