.app-layout {

}

.app-layout-content {
    position: relative;
    z-index: 2;
    background: #fff;
    border-radius: 16px 0 0 0;
    box-shadow: -8px -8px 16px rgba(0, 0, 0, .05);
}

.app-layout-sider {
    height: calc(100vh - 100px);
    position: relative;
    background: #f5f6fa;
}

.ant-layout-sider-children {
    width: 220px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.ant-layout-sider-trigger {
    background: #f5f6fa;
    color: #224373;
}
