.field {
    position: relative;
}

.dropdown {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    top: 80px;
    background-color: #fff;
    border-radius: 4px;
    outline: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.item {

}

.item:hover {
    background: #e6f7ff;
}

.itemBanned {
    color: #000000;
    opacity: 0.5;
}

.inner {
    display: flex;
    align-items: center;
    height: 40px;
}

.address {
    line-height: 1;
    margin-right: 10px;
}

.name {
    line-height: 1;
    color: #666;
}

.stat {
    display: flex;
    align-items: center;
    margin-top: 5px;
    font-size: 11px;
    line-height: 1;
    color: #666;
}

.statIcon {
    font-size: 9px!important;
    margin-right: 1px!important;
}

.statItem1 {
    margin-right: 8px;
    color: #488d47;
}

.statItem2 {
    margin-right: 8px;
    color: #855896;
}

.statItem3 {
    color: #9e3a3a;
}