.group {
    display: flex;
    border-bottom: 1px solid #ccc;
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    background: #eee;
}

.title {
    white-space: nowrap;
    transform-origin: 50% 50%;
    transform: rotate(-90deg);
    font-size: 12px;
}

.body {
    flex: 1;
}
