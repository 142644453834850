.slot {
    position: absolute;
    padding: 0 5px;
    top: 50%;
    transform: translate3d(0, -50%, 1px);
}

.empty {
    position: absolute;
    height: 100%;
}

.over {
    background: rgba(141, 255, 131, 0.25);
}
