.field-value {
    margin-bottom: 24px;
    border-bottom: 1px solid #E7EBF6;
}

.field-value:last-child {
    margin-bottom: 0;
}

.field-value-lbl {
    margin-bottom: 8px;
    font-size: 13px;
    font-weight: 400;
    color: #56636f;
}

.field-value-val {
    margin-bottom: 5px;
    font-size: 14px;
}

