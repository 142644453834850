.ant-collapse-simple {
    border-radius: 4px;
    border: 1px solid #e8e8e8;
    background: #fff;
}

.ant-collapse-simple .ant-collapse-header {
    padding: 0 0 0 16px !important;
}
.ant-collapse-simple > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    left: 8px!important;
    right: auto;
}

.ant-collapse-simple.ant-collapse-icon-position-right .ant-collapse-header {
    padding: 0 16px 0 0 !important;
}

.ant-collapse-simple.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    right: 8px!important;
    left: auto;
}

.ant-collapse-simple > .ant-collapse-item {
    border-bottom: 0;
}

.ant-collapse-simple .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
}
