.main {
    display: flex;
    margin: 0 -4px;
}

.group {
    padding: 0 4px;
}

.prop {

}

.propLabel {
    font-weight: 600;
}

.propValue {

}