.main {
    display: flex;
    align-items: center;
}

.photo {
    margin-right: 8px;
    border-radius: 50%;
    overflow: hidden;
    font-size: 18px;
    min-width: 24px;
}

.name {
    flex: 1;
    min-width: 0;
}

.name > span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.status {
    margin-left: 8px;
}

.actions {
    margin-left: 8px;
}

.size--xs .photo {
    margin-right: 4px;
    font-size: 16px;
    min-width: 16px;
}