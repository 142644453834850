.container {

}

.inline {
    display: inline-block;;
}

.size--small {
    font-size: 13px;
}