.time_range {
    display: flex;
    user-select: none;
}

.time_range_time {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 5px;
    width: 30px;
    height: 30px;
    border: 1px solid #ccc;
}

.time_range_time.active {
    background: #1bf7a7;
}

.time_range_time.disabled {
    background: #ddd;
    cursor: default;
}