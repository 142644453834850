
.ant-menu {
    color: #224373;
    white-space: normal;
}

.ant-dropdown-menu {
    background: #fff;
}

.ant-dropdown-menu-item > .anticon:first-child, .ant-dropdown-menu-submenu-title > .anticon:first-child,
.ant-dropdown-menu-item > span > .anticon:first-child {
    min-width: 12px;
    margin-right: 8px;
}
