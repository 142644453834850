.wrap {
    width: 184px;
}
.logo {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 128px;
    padding: 0;
    margin: 0 16px 0 0;

}

.logo:after {
    content: '';
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 32%;
    background: url(../../../assets/img/logo.png) 0 0 no-repeat;
    background-size: contain;
}
