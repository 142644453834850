.ant-btn {
    border-radius: 4px;
    font-size: 13px;
    font-weight: 600;
}

.ant-btn-white {
    border-color: #fff;
    color: #fff;
}

.ant-btn-xs {
    padding: 0 4px;
    font-size: 12px;
}

.ant-btn-xs > .anticon + span,
.ant-btn-xs > span + .anticon {
    margin-left: 4px;
}