.row {
    display: flex;
    align-items: center;
    height: 40px;
    border-top: 1px solid #eee;
}

.header {
    width: 70px;
    padding: 0 10px;
    font-size: 14px;
}

.body {
    position: relative;
    flex: 1;
    display: flex;
    height: 40px;
}

.slot {
    flex: 1;
    padding: 5px;
    font-size: 10px;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
}

.slot:first-child {
    border-left: 1px solid #ccc;
}

.slotInner {
    display: flex;
    align-items: center;
    padding: 5px;
}

.row:last-child .slot {
    border-bottom: 0;
}


.tasks {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
