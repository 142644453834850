.dateControl {
    text-align: center;
}

.dateControlMain {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.dateControlTitle {
    margin: 0 15px;
    width: 180px;
    font-weight: 700;
}

.dateControlBottom {
    font-size: 13px;
}

.prev, .next {
    cursor: pointer;
}
