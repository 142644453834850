
.navigation {
    border-right: 0;

}

.navigation .ant-menu-item .anticon,
.navigation .ant-menu-submenu-title .anticon {
    font-size: 20px;
}

.navigation .ant-menu-item-selected {
    background-color: transparent !important;
}


.navigation .ant-menu-item::after {
    right: auto;
    left: 0;
}


.navigation .ant-menu-selected::after,
.navigation .ant-menu-item-selected::after {
    opacity: 1;
    transform: scale(1);
}
