@import "layout/index.css";

@import "menu.css";
@import "table.css";
@import "calendar.css";
@import "modal.css";
@import "form.css";
@import "field_value.css";
@import "btn.css";
@import "collapse.css";

*,
*::before,
*::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
}

.disable-hover, .disable-hover * {
    pointer-events: none !important;
}

html {

}

body {
    font-family: 'Helvetica', sans-serif;
    font-size: 16px;

    font-weight: 500;
    line-height: 1.375;
    /*color: #414954;
    fill: #414954;*/
    color: #000;
    margin: 0;
    padding: 0;
    background: #f5f6fa;
    /*user-select: none;*/

    text-rendering: optimizeLegibility;
}

img {
    max-width: 100%;
}

a.color-main {
    color: #404346;
}

.ant-layout {
    background: transparent;
}

.photo {
    background-size: cover;
    /*background-position: 50% 50%;*/
}

.ant-calendar, .ant-time-picker-panel-inner, .ant-select-dropdown {
    background: #fff;
}

.dateControlPicker .ant-input-lg {
    height: 46px;
    padding: 6px 11px;
    font-size: 24px;
}

.dateControlPicker .anticon-calendar {
    font-size: 20px;
    margin-top: -10px;
    right: 18px;
}

.work-mode0 {
    background-color: #65c16f;
}

.work-mode1 {
    background-color: #F2A600;
}


.drag {
    cursor: move;
}

.actionLink {
    margin-left: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    color: #02c385;
    cursor: pointer;
    transition: all 0.3s ease-out;
    white-space: nowrap;
}

.actionLink .anticon {
    margin-right: 5px;
    font-size: 16px;
}

.red {
    color: #ee513b;
}

.green {
    color: #02c385;
}

.ant-collapse-content-box {
    background: #fff;
}

.ant-card-head {
    background: #fafafa;
}
.ant-card-actions {
    background: #fff;
}

.ant-card-small .ant-card-head-title {
    font-size: 16px;
    font-weight: 600;
}

.ant-card-small .ant-card-actions > li {
    margin: 4px 0;
    background: #fff;
}

.ant-tag {
    padding: 0 5px;
    color: #1890ff;
    border: 1px solid #1890ff;
    background: #fff;
    display: flex;
    align-items: center;
}

.ant-tag span {
    flex: 1;
}

.ant-tag .anticon-close {
    position: relative;
    left: 2px;
    color: #1890ff;
}
