.nav {
    display: flex;
    align-items: center;
}

.navBtns {
    margin-right: 32px;
}

.navDate {
    max-width: 250px;
}
